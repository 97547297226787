import 'assets/styles/app.scss';

const loadedComponents = [];

document.addEventListener( 'DOMContentLoaded', async () => {
	/**
	 * Bootstrap the components
	 */


	const formLogic = await import(
		/* WebpackMode: "lazy" */
		/* webpackPrefetch: true */
		/* webpackPreload: true */
		`components/request-demo-form/request-demo-form.js`
		);

		formLogic.default();

	for ( const dataComponent of document.querySelectorAll( '[data-component]' ) ) {
		let componentName = dataComponent.dataset.component;

		if ( loadedComponents.indexOf( componentName ) === - 1 ) {
			loadedComponents.push( componentName );

			try {
				let component = await import(
					/* WebpackMode: "lazy" */
					/* webpackPrefetch: true */
					/* webpackPreload: true */
					`components/${componentName}/${componentName}.js`
					);

				component.default();
			} catch ( error ) {
				console.error( 'Loading error: ', error );
			}
		}
	}
} );
